/** table */
.card .table {
  color: inherit;

  thead th,
  tbody td {
    &:first-child {
      padding-left: 47px;
    }
    &:last-child {
      padding-right: 47px;
    }
  }

  thead th {
    border: none;
  }

  tbody td {
    border-width: 1px;
    border-color: #ecf5ff;
    border-bottom: 1px solid;
    border-bottom-color: #ecf5ff;
  }

  tr td {
    border-top-width: 1px;
    border-collapse: collapse;
    border-color: $color-default;
    background-color: #fff !important;
    border-top-style: solid;
  }
}
