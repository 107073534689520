// Variable overrides
$color-default: #133c4a;
$color-primary: #202e48;
$secondary: #9fa5b1;
// $info: ;
// $warning: ;
$color-danger: #f73b4a;
$color-success: #88c68b;

@import 'sidebar';
