/*forms*/
.invalid-feedback {
  color: $color-danger !important;
  font-size: 10px;
  text-transform: capitalize;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #e55353 !important;
}

.form {
  .form-group {
    margin-top: 20px;
  }

  .form-control {
    height: 47px;
    border-radius: 0;
    border-color: $color-default;
  }

  .form-control-file {
    border-radius: 4px;

    &.is-invalid {
      border: 1px solid #e55353;
    }
  }

  .group-form-upload-file label {
    cursor: pointer;
    img.img-thumbnail {
      width: 100%;
      object-fit: contain;
    }
  }

  input {
    color: $secondary;

    &:placeholder {
      color: $secondary;
    }
  }

  .input-group,
  .input-group input {
    height: 40px;
  }

  .u-form-actions {
    margin-top: 20px;
  }
}
