/** card header */
.card {
  color: $color-default;
  border: none;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 47px 47px;
    border-color: $color-default;

    h1 {
      font-size: 30px;
      font-weight: bold;
    }
  }

  .card-body {
    padding: 0;
  }
}
