@import './variables';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

html,
body,
#root {
  height: 100%;
}

html,
body,
body * {
  font-family: 'Montserrat', sans-serif;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #9fa5b1;
  -webkit-background-clip: text;
}

/* basics */
.u-content {
  padding: 0 47px 20px;
  &-xs {
    @media screen and(min-width: 540px) {
      padding: 0 24px 20px;
    }
  }
}

@import './card';
@import './form';
@import './table';
@import './button';
@import './pagination';
@import './reports';

/* rewrites */
.ck-editor__editable_inline {
  min-height: 200px;
}

.w25 {
  max-width: 25px;
}

.mb-3 {
  margin-bottom: 20px !important;
}

/*** header */
.c-header {
  height: 60px;
  .c-header-nav {
    h2 {
      font-size: 20px;
    }
  }
}

/** footer */
.c-footer {
  background-color: #fff;
}

.c-border-dashed {
  border-style: dashed !important;
}

.c-border-r-1 {
  border-radius: 0.25rem;
}

.c-drag-n-drop {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-preview-img {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(118px, 1fr));
  gap: 10px;
  max-height: 910px;
}
.c-preview-img > * {
  aspect-ratio: 1/1;
}

.c-preview-img button {
  top: 0;
  right: 0;
  background-color: rgba(235, 237, 239, 0.5);

  @media (hover: hover) {
    &:hover svg {
      color: $color-danger !important;
    }
  }
}
.c-preview-img button svg {
  fill: #fff;
}

.c-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-events-none {
  pointer-events: none;
}

.c-cover img {
  height: 100%;
  width: 100%;
  // object-fit: cover !important;
}
/* custom owner person fisical or legal */
.c-owner label {
  cursor: pointer;
  transition: all 333ms ease-in-out;

  &:has(input[type='radio']:checked) {
    background-color: #202e48;
    color: #edf2f7;
  }

  &:has(input[type='radio']) {
    background-color: #ebedef;
    color: #133c4a;
  }
  input[type='radio'] {
    display: none;
  }
}

/** banner forms */
.u-banner-form {
  .form-check.is-video {
    margin-top: 50px;
  }
}

.i-status svg {
  width: 30px;
  height: 30px;
}

.editor-fix * {
  font-family: monospace !important;
  font-size: 12px !important;
  direction: ltr !important;
  text-align: left !important;
}

.input-color {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  max-width: 260px;

  input {
    height: calc(1.5em + 0.75rem + 2px);
  }

  input[type='text'] {
    text-align: center;
  }
}

.card .card-theme {
  padding: 47px;
}

.theme-colors {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}

.c-avatar {
  filter: blur(0px);
  background-color: transparent;

  &.lazy {
    background-color: #f0f0f0;
    filter: blur(5px);
    > img {
      opacity: 0;
    }
  }

  &,
  & > img {
    transition: all 333ms ease-in-out;
  }
}

.content-collapse {
  .form-group {
    flex-basis: 32%;
  }
  .content-collapse-button {
    cursor: pointer;
    background-color: #202e48;
    color: #ffffff;

    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    gap: 10px;
  }

  .collapse {
    border: 1px solid #202e48;
    padding: 10px;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
    transition: height 1s ease-out;
  }

  @media (max-width: 767px) {
    .form-group {
      flex-basis: 100%;
    }
  }

  @media (max-width: 968px) {
    .form-group {
      flex-basis: 49%;
    }
  }
}

@media (hover: hover) {
  .form-check-input,
  label,
  .c-pointer {
    cursor: pointer;
  }
}

.form-check-input {
  margin-top: 0.2rem;
}

.c-vh-auto {
  height: calc(100vh - 145px) !important;
}

.c-blocked {
  padding: 8px;
  position: relative;
  border-radius: 2px;
  > *:not(:first-child) {
    opacity: 0.5 !important;
    pointer-events: none !important;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(3px);
    background-color: rgba(235, 235, 235, 0.2);
    z-index: 1;
    border-radius: 2px;
  }
}

.c-blocked-btn {
  width: fit-content;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;
}
