.pagination {
  .page-item {
    .page-link:not(.disabled) {
      border-color: $color-default;
      color: $color-default;
    }

    &.active .page-link {
      border-color: #f73b4a;
      background-color: #f73b4a;
      color: #fff;
    }
  }
}
