.btn {
  height: 35px;
  font-weight: bold;

  &.c-btn {
    &.btn-lg {
      height: 45px;

      &:disabled {
        cursor: unset;
        border-color: #bdbdbd;
        background-color: #bdbdbd;
      }
    }
  }

  &.btn-danger {
    background-color: $color-danger;
  }

  &.btn-link {
    color: $color-default;
    border-color: $color-default;
  }

  &.btn-white {
    color: $color-default;
    background-color: #fff;
    border-color: $color-default;
  }

  &.btn-submit {
    margin-left: 30px;

    .spinner-border {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  &.btn-default {
    background-color: $color-default;
    border-color: $color-default;
    color: #fff;
  }
}

a.btn {
  line-height: 35px;
  padding-top: 0;
  padding-bottom: 0;
}
