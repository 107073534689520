// Colors dependency
@import "~@coreui/coreui/scss/variables/colors";

$sidebar-imobiu-color:    $white !default;
$sidebar-imobiu-bg:       $color-primary !default;
$sidebar-imobiu-borders:  none !default;

$sidebar-imobiu-brand-color:  $white !default;
$sidebar-imobiu-brand-bg:     $sidebar-imobiu-bg !default;

$sidebar-imobiu-header-bg:  rgba($black, .2) !default;

$sidebar-imobiu-form-border:              0 !default;
$sidebar-imobiu-form-bg:                  rgba($color-primary, .1) !default;
$sidebar-imobiu-form-color:               $white !default;
$sidebar-imobiu-form-placeholder-color:   rgba($white, .7) !default;

$sidebar-imobiu-nav-title-color:      rgba($white, .6) !default;
$sidebar-imobiu-nav-link-color:       rgba($white, .8) !default;
$sidebar-imobiu-nav-link-bg:          transparent !default;
$sidebar-imobiu-nav-link-icon-color:  rgba($white, .5) !default;
$sidebar-imobiu-nav-link-borders:     0 !default;

$sidebar-imobiu-nav-link-hover-color:       $white !default;
$sidebar-imobiu-nav-link-hover-bg:          darken( $color-primary, 5% ) !default;
$sidebar-imobiu-nav-link-hover-icon-color:  $white !default;
$sidebar-imobiu-nav-link-hover-borders:     0 !default;

$sidebar-imobiu-nav-link-active-color:       $color-danger !default;
$sidebar-imobiu-nav-link-active-bg:          rgba($white, .05) !default;
$sidebar-imobiu-nav-link-active-icon-color:  $color-danger !default;
$sidebar-imobiu-nav-link-active-borders:     0 !default;

$sidebar-imobiu-nav-link-disabled-color:       darken($white, 30%) !default;
$sidebar-imobiu-nav-link-disabled-bg:          $sidebar-imobiu-bg !default;
$sidebar-imobiu-nav-link-disabled-icon-color:  $sidebar-imobiu-nav-link-icon-color !default;
$sidebar-imobiu-nav-link-disabled-borders:     0 !default;

$sidebar-imobiu-nav-dropdown-color:                  $white !default;
$sidebar-imobiu-nav-dropdown-bg:                   rgba(0, 0, 0, .2) !default;
$sidebar-imobiu-nav-dropdown-borders:                0 !default;
$sidebar-imobiu-nav-dropdown-indicator-color:        rgba($white, .5) !default;
$sidebar-imobiu-nav-dropdown-indicator:              str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-imobiu-nav-dropdown-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;
$sidebar-imobiu-nav-dropdown-indicator-hover-color:  $sidebar-imobiu-nav-link-hover-color !default;
$sidebar-imobiu-nav-dropdown-indicator-hover:        str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-imobiu-nav-dropdown-indicator-hover-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;

$sidebar-imobiu-nav-tabs-border-color:              rgba($black, .2) !default;
$sidebar-imobiu-nav-tabs-link-active-bg:            rgba($white, .05) !default;
$sidebar-imobiu-nav-tabs-link-active-border-color:  rgba($black, .2) rgba($black, .2) theme-color("primary") !default;
$sidebar-imobiu-nav-tabs-link-hover-bg:             rgba($black, .2) !default;
$sidebar-imobiu-nav-tabs-link-hover-border-color:   rgba($black, .2) rgba($black, .2) $gray-200 !default;
$sidebar-imobiu-tab-content-borders:                ( top: 1px solid rgba($black, .2)) !default;

$sidebar-imobiu-footer-bg:        rgba($color-primary, .2) !default;
$sidebar-imobiu-footer-borders:   0 !default;

$sidebar-imobiu-minimizer-borders:                0 !default;
$sidebar-imobiu-minimizer-bg:                     rgba($black, .2) !default;
$sidebar-imobiu-minimizer-indicator-color:        $gray-600 !default;
$sidebar-imobiu-minimizer-indicator:              str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-imobiu-minimizer-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;
$sidebar-imobiu-minimizer-hover-bg:              rgba(0, 0, 0, .3) !default;
$sidebar-imobiu-minimizer-hover-indicator-color:  $sidebar-imobiu-nav-link-hover-color !default;
$sidebar-imobiu-minimizer-hover-indicator:        str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-imobiu-minimizer-hover-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;

// Adds 'imobiu' to the color scheme map
$sidebar-variants-map: (
  imobiu: (
    "sidebar-color":                               $sidebar-imobiu-color,
    "sidebar-bg":                                  $sidebar-imobiu-bg,
    "dark-theme-sidebar-bg":                       0,
    "sidebar-borders":                             $sidebar-imobiu-borders,
    "dark-theme-sidebar-borders":                  0,
    "sidebar-brand-color":                         $sidebar-imobiu-brand-color,
    "sidebar-brand-bg":                            $sidebar-imobiu-brand-bg,
    "sidebar-header-bg":                           $sidebar-imobiu-header-bg,
    "sidebar-form-border":                         $sidebar-imobiu-form-border,
    "sidebar-form-bg":                             $sidebar-imobiu-form-bg,
    "sidebar-form-color":                          $sidebar-imobiu-form-color,
    "sidebar-form-placeholder-color":              $sidebar-imobiu-form-placeholder-color,
    "sidebar-nav-title-color":                     $sidebar-imobiu-nav-title-color,
    "sidebar-nav-link-color":                      $sidebar-imobiu-nav-link-color,
    "sidebar-nav-link-bg":                         $sidebar-imobiu-nav-link-bg,
    "sidebar-nav-link-icon-color":                 $sidebar-imobiu-nav-link-icon-color,
    "sidebar-nav-link-borders":                    $sidebar-imobiu-nav-link-borders,
    "sidebar-nav-link-hover-color":                $sidebar-imobiu-nav-link-hover-color,
    "sidebar-nav-link-hover-bg":                   $sidebar-imobiu-nav-link-hover-bg,
    "sidebar-nav-link-hover-icon-color":           $sidebar-imobiu-nav-link-hover-icon-color,
    "sidebar-nav-link-hover-borders":              $sidebar-imobiu-nav-link-hover-borders,
    "sidebar-nav-link-active-color":               $sidebar-imobiu-nav-link-active-color,
    "sidebar-nav-link-active-bg":                  $sidebar-imobiu-nav-link-active-bg,
    "sidebar-nav-link-active-icon-color":          $sidebar-imobiu-nav-link-active-icon-color,
    "sidebar-nav-link-active-borders":             $sidebar-imobiu-nav-link-active-borders,
    "sidebar-nav-link-disabled-color":             $sidebar-imobiu-nav-link-disabled-color,
    "sidebar-nav-link-disabled-bg":                $sidebar-imobiu-nav-link-disabled-bg,
    "sidebar-nav-link-disabled-icon-color":        $sidebar-imobiu-nav-link-disabled-icon-color,
    "sidebar-nav-link-disabled-borders":           $sidebar-imobiu-nav-link-disabled-borders,
    "sidebar-nav-dropdown-color":                  $sidebar-imobiu-nav-dropdown-color,
    "sidebar-nav-dropdown-bg":                     $sidebar-imobiu-nav-dropdown-bg,
    "sidebar-nav-dropdown-borders":                $sidebar-imobiu-nav-dropdown-borders,
    "sidebar-nav-dropdown-indicator-color":        $sidebar-imobiu-nav-dropdown-indicator-color,
    "sidebar-nav-dropdown-indicator":              $sidebar-imobiu-nav-dropdown-indicator,
    "sidebar-nav-dropdown-indicator-hover-color":  $sidebar-imobiu-nav-dropdown-indicator-hover-color,
    "sidebar-nav-dropdown-indicator-hover":        $sidebar-imobiu-nav-dropdown-indicator-hover,
    "sidebar-nav-tabs-border-color":               $sidebar-imobiu-nav-tabs-border-color,
    "sidebar-nav-tabs-link-active-bg":             $sidebar-imobiu-nav-tabs-link-active-bg,
    "sidebar-nav-tabs-link-active-border-color":   $sidebar-imobiu-nav-tabs-link-active-border-color,
    "sidebar-nav-tabs-link-hover-bg":              $sidebar-imobiu-nav-tabs-link-hover-bg,
    "sidebar-nav-tabs-link-hover-border-color":    $sidebar-imobiu-nav-tabs-link-hover-border-color,
    "sidebar-tab-content-borders":                 $sidebar-imobiu-tab-content-borders,
    "sidebar-footer-bg":                           $sidebar-imobiu-footer-bg,
    "sidebar-footer-borders":                      $sidebar-imobiu-footer-borders,
    "sidebar-minimizer-borders":                   $sidebar-imobiu-minimizer-borders,
    "sidebar-minimizer-bg":                        $sidebar-imobiu-minimizer-bg,
    "sidebar-minimizer-indicator-color":           $sidebar-imobiu-minimizer-indicator-color,
    "sidebar-minimizer-indicator":                 $sidebar-imobiu-minimizer-indicator,
    "sidebar-minimizer-hover-bg":                  $sidebar-imobiu-minimizer-hover-bg,
    "sidebar-minimizer-hover-indicator-color":     $sidebar-imobiu-minimizer-hover-indicator-color,
    "sidebar-minimizer-hover-indicator":           $sidebar-imobiu-minimizer-hover-indicator
  ),
);
