.report-v2 {
  padding: 2rem 47px 2.5rem;
  background: #202e48;
  color: #fff;

  .form-group {
    margin-bottom: .5rem;

    .form-control {
      border: 0;
    }

    .form-control:focus {
      box-shadow: 0 0 0 0.2rem rgba(236, 216, 216, 0.25);
    }

    .react-datetime-picker__wrapper {
      border-color: transparent;
      
      input {
        color: #768192;
      }
    }

    .react-datetime-picker__button {
      padding: 0;

      svg, path, polygon {
        fill: #768192;
        stroke: #768192;
      }
    }

    .react-datetime-picker__clear-button {
      padding-top: 1.75px;
      margin-right: .25rem;
    }
  }
}

.report {
  padding: 10px 47px;
  background: #202e48;

  .report-fields {
    margin: 0;
    padding: 20px 0;

    &.row {
      padding: 0;
    }

    > .col:first-child {
      padding-left: 0;
    }

    > .col:last-child {
      padding-right: 0;
    }
  }

  label {
    color: #fff;
  }

  .form-control {
    height: 40px;
  }

  .form-group .date-picker-input {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    height: 40px;

    .react-datetime-picker__wrapper {
      border-color: #d8dbe0;
      border-radius: 4px;
      padding: 6px 12px;
      background-color: #fff;
    }

    .c-switch-danger .c-switch-input:checked + .c-switch-slider {
      background-color: $color-danger;
    }

    .c-switch-slider::before {
      height: 34px;
    }
  }
}

.results-count {
  padding: 1.25rem 47px;
  background-color: #f8f9fb;
}
